/* 包含当前的子站的名称和对应的省份区号和对应的需要默认选中的城市的区号*/
const zipCodeMap = new Map([
    ["hn", ["430000", 0]],
    ["tj", ["120000", 0]],
    ["nx", ["640000", 0]],
    ["hlj", ["230000", 0]],
    ["xj", ["650000", "651000"]],
    ["cq", ["500000", 0]],
    ["ln", ["210000", 0]],
    ["henan", ["410000", 0]],
    ["qh", ["630000", 0]],
]);
const host = window.location.host;
const __ad = {
    // province: hostname.indexOf("hn") > -1 ? '430000' : (hostname.indexOf("nx") > -1 ? '640000' : '120000'),
    // host: window.location.hostname.indexOf("test") > -1 ? 'www-test.kczg.org.cn' : 'www.kczg.org.cn',
    host: window.location.hostname.indexOf("test1") > -1 ? 'www-test1.kczg.org.cn' : (window.location.hostname.indexOf("test") > -1 ? 'www-test.kczg.org.cn' : 'www.kczg.org.cn'),
    createHtmlAd() {
        let defaultProvince = "";
        let defaultCity = "";

        for (let [name, [provinceCode, cityCode]] of zipCodeMap) {
            if (host.includes(name)) {
                defaultProvince = provinceCode;
                defaultCity = cityCode;
            }
        }

        let [province, city] = [util.queryString("hub_province") || defaultProvince, util.queryString("hub_city") || defaultCity];

        let hub_province_id = $(".hub_subsite").data('province-id');
        let hub_city_id = $(".hub_subsite").data('city-id');
        province = hub_province_id || province;
        city = hub_city_id || city;

        let ccw = '';

        // 湖南中心 添加查策网飘窗
        if ($('._hnIndex').length) {
            ccw = ` <a href="https://www.chacewang.com/" target="_blank" class="layer-box">
                        <img src="/vendors/images/hn/ccw.png" alt="">
                    </a>`;
        }

        $('body').append(`<div class="r-opts full-height-opts" style="display: none;" id="_castAdBox">
           ${ccw}
            <a href="https://${__ad.host}/zt530activity/add?type=1&province=${province}&city=${city}" target="_blank"></a>
            <a href="https://${__ad.host}/zt530activity/achievementapply?province=${province}&city=${city}" target="_blank"></a>
            <a href="https://${__ad.host}/zt530activity/meetingapply?type=1&province=${province}&city=${city}" target="_blank"></a>
            <a href="https://${__ad.host}/zt530activity/addNewspage?type=1&province=${province}&city=${city}" target="_blank"></a>
            <a href="https://${__ad.host}/crowd/shareorganization?province=${province}&city=${city}" target="_blank"></a>
            <div style="position: relative;">
                <span class="delete-layer-btn" id="_closeLayerBtn" style="position: absolute;right: 0;top: 0;width: 30px;height: 20px; display: block; z-index: 2;cursor: pointer;"></span>
                <a style="width: 164px;height: 150px;background: url('/vendors/images/index-win-layer3.png') no-repeat center/cover;background-size: 100% 100%;margin-left: -70px;" href="https://www.kczg.org.cn/article/detail?id=5539614" target="_blank"></a>
            </div>
            </div>`);
        __ad.initPc();
    },
    initPc() {
        function getStyle(obj, attr) {
            return obj.currentStyle ? obj.currentStyle[attr] : getComputedStyle(obj, false)[attr];
        }

        function tween(obj, attr, target, right, options) {
            var opt = options || {};

            var start = parseInt(getStyle(obj, attr)),
                dis = target - start,
                iCount = Math.round(500 / 30),
                iNow = 0;

            clearInterval(obj.timer);
            obj.timer = setInterval(function () {
                iNow++;

                var d = 1 - iNow / iCount,
                    cur = start + dis * (1 - d * d * d);

                obj.style[attr] = cur + 'px';
                obj.style.right = right + 'px';
                if (iNow == iCount) {
                    clearInterval(obj.timer);
                }
            }, 30);
        }


        function addStylesheetRules(decls) {
            var doc = document,
                style = doc.createElement('style');

            doc.getElementsByTagName('head')[0].appendChild(style);
            if (!window.createPopup) {
                style.appendChild(doc.createTextNode(''));
            }
            var s = doc.styleSheets[doc.styleSheets.length - 1];

            for (var i = 0, dl = decls.length; i < dl; i++) {
                var j = 1,
                    decl = decls[i],
                    selector = decl[0],
                    rulesStr = '';

                if (Object.prototype.toString.call(decl[1][0]) === '[object Array]') {
                    decl = decl[1];
                    j = 0;
                }
                for (var rl = decl.length; j < rl; j++) {
                    var rule = decl[j];

                    rulesStr += rule[0] + ':' + rule[1] + (rule[2] ? ' !important' : '') + ';\n';
                }

                if (s.insertRule) {
                    s.insertRule(selector + '{' + rulesStr + '}', s.cssRules.length);
                } else {
                    s.addRule(selector, rulesStr, -1);
                }
            }
        }

        function interjectStyles() {

            addStylesheetRules([
                ['#_castAdBox',
                    ['display', 'block!important'],
                    ['position', 'absolute'],
                    ['top', '30%'],
                    ['right', '0'],
                    ['z-index', '9999'],
                    ['margin-top', '-70px']
                ]
            ]);
        }

        function init() {
            if (!init.status) {
                interjectStyles();
                init.status = true;
            }

            var adBox = document.getElementById('_castAdBox'),
                winW = window.innerWidth ||
                    document.documentElement.clientWidth ||
                    document.body.clientWidth,
                winH = window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight,
                nTop = (winH - adBox.offsetHeight) / 2,
                nRight = 0,
                sidebarW = (winW - 1200) / 2,
                scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

            if (sidebarW > 240) {
                nRight = sidebarW - 240;
            }

            adBox.style.marginTop = -70;
            nTop += scrollTop;

            tween(adBox, 'top', nTop + 60, nRight);

            window.onscroll = function () {
                var winH = document.documentElement.clientHeight,
                    nTop = (winH - adBox.offsetHeight) / 2,
                    scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

                nTop += scrollTop;

                tween(adBox, 'top', nTop, nRight);
                // tween(adBox, 'top', nTop + 60, nRight);
            };
            setTimeout(() => {
                adBox.style.visibility = 'visible';
            }, 50);
        }

        window.onload = init;
        window.onresize = init;
    },
    handleClose(){
        $(this).parent().remove();
    }
};
__ad.createHtmlAd();


$(function () {
    $(document)
        .on("click", "#_closeLayerBtn", __ad.handleClose)
})
